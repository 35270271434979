import React from "react";
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import './Hero.sass'

const Hero = () => (
    <StaticQuery
    query={graphql`
        query HeaderImageQuery {
            headerImage: file (
                relativePath: { regex: "/hero.png/" }
            ) {
                childImageSharp {
                    fluid(
                        # maxWidth: 800
                        # maxHeight: 200
                        # width: 400
                        # height: 300
                        quality: 85
                        # duotone: {
                        #   highlight: "#cccccc",
                        #   shadow: "#192550"
                        # }
                        # duotone: { highlight: "#ffffff", shadow: "#334499" }
                    ) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `}
      render={data => (
          <section className="page-hero section">
            <div className="hero-image-container">
                <Img
                fluid={ data.headerImage.childImageSharp.fluid }/>
                <div className="bg-tint-3"></div>
            </div>
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <h1 className="subtitle hero-title">The best <span className="hero-title-highlight">esports betting sites</span>!</h1>
                        <p className="hero-text">
                            Esportstips provides the best betting site reviews, comparison and esports betting bonuses.
                        </p>
                    </div>
                    <div className="column">
                    </div>
                </div>
            </div>
          </section>
      )}
    />
  )
export default Hero
