import React, { Component } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import './ReferenceItems.sass'

class ReferenceItems extends Component {
    getReferenceList() {
        const itemList = [];
        this.props.references.forEach(item => {
          itemList.push({
            uid: item.node.uid,
            organization: item.node.data.organization_name.text,
            title: item.node.data.case_title.text,
            description: item.node.data.case_description.html,
            website: item.node.data.case_website.url,
            logo: item.node.data.organization_logo.localFile.childImageSharp.fluid,
            image: item.node.data.case_image.localFile.childImageSharp.fluid,
            // image: item.node.data.image.localFile.childImageSharp.fluid
          });
        });
        return itemList;
      }
    render () {
        const references = this.getReferenceList()
        console.log(references)
        return (
            <div>
            {
                references.map((reference, index) => (
                    <div class="columns reference-item">
                                <div class="column reference-item-img">
                                    <Link to={'/references/' + reference.uid}>
                                        <Img fluid={ reference.image } alt={ reference.organization }/>
                                        <div class="reference-item-img-tint">{reference.organization}</div>
                                    </Link>
                                </div>
                        <div class="column">
                        <div className="reference-item-content">
                            <div>
                                <div className="reference-item-title"><b>{reference.title}</b><small></small></div>
                                    <p dangerouslySetInnerHTML={{ __html: reference.description}}>
                                    </p>
                                        <Link to={'/references/' + reference.uid}>
                                            {/* <button class="button is-primary is-inverted is-outlined reference-item-button">Read more</button> */}
                                            <button class="button is-success reference-item-button">Read more</button>
                                        </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}

export default ReferenceItems
